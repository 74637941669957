.testimonials {
	margin: 1rem 0;
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
	-webkit-column-gap: 2rem;
	-moz-column-gap: 2rem;
	column-gap: 2rem;
	-webkit-column-width: 100%;
	-moz-column-width: 100%;
	column-width: 100%;

	.timg {
		width: 100%;
	}

	.testimonial {
		max-width: 100%;
		width: 100%;
		height: auto;
		margin-bottom: 1rem;
		display: inline-block;
		position: relative;
		padding: 40px;
		text-align: center;
		background: $color-white;
		position: relative;
		border-radius: 5px;
		box-shadow: 0 1px 1px -5px rgba(0,0,0,0.15), 0 12px 0 -5px #E5E5E5, 0 12px 1px -4px rgba(0,0,0,0.1), 0 10px 12px rgba(0,0,0,0.2);
		margin-bottom: 40px;

		&--green {
			background: $color-green;
			box-shadow: none;
			color: $color-white;
			border-radius: 0;

			.testimonial-title {
				padding: 10px 0;
			}
		}

		&-title {
			padding: 0 20px;
			font: bold 25px/27px $font-open-sans;
			text-transform: none;
			letter-spacing: 0;

			h2 {
				margin-top: 0;
				font: bold 25px/27px $font-open-sans;
			}
		}

		.fa-quote-left {
			font-size: 38px;
			color: #EEE;
			position: absolute;
			top: 37px;
			left: 37px;
		}

		&-desc {
			padding-bottom: 15px;
		}
	}
}
@media (min-width: 768px) {
	.testimonials {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
		-webkit-column-width: 50%;
		-moz-column-width: 50%;
		column-width: 50%;
	}
}
@media (min-width: 1200px) {
	.testimonials {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-width: 33.33333333333333%;
		-moz-column-width: 33.33333333333333%;
		column-width: 33.33333333333333%;
	}
}
