.rooms-list {
	.example-room {
		padding-top: 0!important;
		margin-top: 30px;
		position: relative;
		text-align: center;
		overflow: hidden;
		height: 290px;

		// &:hover .example-room-info {
		// 	height: 100%;
		// 	bottom: 1px;
		// }

		a,
		a:active,
		a:hover {
			color: $color-black;
			cursor: pointer;
			text-decoration: none;
		}

		&-info {
			background-color: rgb(238, 238, 238);
			height: 80px;
			bottom: 0;
			width: 100%;
			position: static;
			overflow: hidden;
			transition: all 1s;

			a {
				color: $color-black;
				cursor: pointer;
				height: 100%;
			}

			&-title {
				font: bold 25px/30px $font-open-sans;
				text-transform: none;
				letter-spacing: 0;
				padding: 15px;
				top: 0;
			}

			&-desc {
				padding: 25px 30px;
			}
		}

		&-img {
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
			max-height: 320px;
			width: 100%;
			height: 200px;
		}

		img {
			width: 100%;
			transition: transform 2s;
		}


	}
}
@media (min-width: 768px) {
	.rooms-list {
		.example-room {
			margin-top: 0;
			padding: 10px;
			height: 250px;
			&-info {height: 90px;}
			&:hover .example-room-info {
				height: 100%;
				bottom: 1px;
			}

			&-img {
				height: 190px;
			}
			&:hover img {
				transform: scale(1.02);
				overflow: hidden;
			}
		}
	}
}
@media (min-width: 992px) {
	.rooms-list {
		.example-room {
			&-img {
				height: 240px;
			}

			&-info {
				position: absolute;
				background-color: fade-out($color-white, .5);
			}
		}
	}
}
@media (min-width: 1200px) {
	.rooms-list {
		.example-room {
			height: 350px;

			&-img {
				height: 320px;
			}
		}
	}
}
@media (max-width: 768px) {
	.xs-no-padd{
		padding: 0!important;
	}
	.img-thumbnail{
		padding: 0;
	}
}
