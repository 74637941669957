#loader {
	position: absolute;
	background: url("../img/sunny.svg")no-repeat center center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	z-index: 12;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
/* Add animation to "page content" */
// .animate-bottom {
//   position: relative;
//   -webkit-animation-name: animatebottom;
//   -webkit-animation-duration: 1s;
//   animation-name: animatebottom;
//   animation-duration: 1s
// }
@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}

	to {
		bottom: 0;
		opacity: 1;
	}
}
@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0;
	}

	to {
		bottom: 0;
		opacity: 1;
	}
}
