.wrapper-room{
	.room{
		&--image{
			border: 1px solid $color-grey-light;
			padding: 4px;
		}
		&--desc{
			padding: 50px 0;
		}
		&--tag-wrapper{
			text-align: center;
			.tag{
				margin: 0 7px 10px 0;
			    padding: 12px 13px;
			    font: bold 12px/16px $font-open-sans;
			    letter-spacing: .2px;
			    border-radius: 4px;
			    background: #EEE;
			    box-shadow: 1px 1px 0 rgba(0,0,0,.12);
			    display: inline-block;
			    list-style: none;
			}
		}

	}

}
