.wrapper-add-comment {
	background-color: rgba(0, 0, 0, 0.6);
	width: 100%;
	height: 100%;
	position: fixed;
	display: none;
	top: 0;
	right: 0;
}

.add-comment {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999990;
	display: none;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	-webkit-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	box-shadow: 0 0 1px rgba(0,0,0,.3), 0 0 30px rgba(0,0,0,.3);
	padding: 40px;
	min-height: 535px;
	background-color: #cce0d6;
	color: $color-black;

	

	&-title {
		text-align: center;
		margin: 20px 0;
		font: 600 18px/20px $font-open-sans;
		text-transform: uppercase;
		letter-spacing: 1.2px;
	}

	&-desc {
		margin-bottom: 30px;
		font: 14px/22px $font-open-sans;
		text-align: center;
		letter-spacing: 0.4px;
	}

	form {
		input,
		textarea {
			width: 100%;
			margin-bottom: 7px;
			padding: 12px 15px;
			font: 14px/20px $font-open-sans;
			letter-spacing: 0.4px;
			border: none;
			box-sizing: border-box;
			border-radius: 3px;
			box-shadow: 1px 1px 0 rgba(0,0,0,.1);
			position: relative;
			display: block;
		}

		textarea {
			height: 120px;
		}

		input {
			height: 45px;
		}
	}
}
@media (min-width:992px) {
	.add-comment {
		width: 45%;
	}

	.wrapper-add-comment {}
}
