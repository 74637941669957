.wrapper-gallery {
	margin: 0;
	padding: 10px;
	.col-xs-12{
		padding-left: 0;
		padding-right: 0;
	}
}

.gallery-intro {
	text-align: center;
}
.toolbar {
	border-radius: 0;
	font-size: 14px;
	text-align: center;
	text-transform: uppercase;
	margin-top: 50px;
	color: #3b3d42;
	display: inline-block;
	padding-bottom: 25px;
}

.portfolio {
	display: none;
	.tile {
		-webkit-transform: scale(0);
		transform: scale(0);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		background-color: black;
		padding: 0;
	}

	.tile:hover {
		filter: grayscale(60%);
	}

	.scale-anm {
		transform: scale(1);
	}

	.tile img {
		max-width: 100%;
		display: inline-block;
		height: 218px;
		object-fit: cover;
		width: 100%;
	}

	::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

.btn-gallery {
	margin: 0 10px;
	font-weight: bold;
	border-bottom: 1px solid #E5E5E5;
	display: inline-block;
	&:hover{
		cursor: pointer;
	}

	&.active{
		color: #FFD900 !important;
	}
}
@media (min-width: 768px) {
	.portfolio {
		.wrapper-gallery {
			padding: 40px;
		}
		div{
			padding: 0;
		}
	}
}
