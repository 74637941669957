// break point nav change
@media (max-width: 992px) {
	.navbar-header {
		float: none;
	}

	.navbar-left,
	.navbar-right {
		float: none !important;
	}

	.navbar-toggle {
		display: block;
		z-index: 9;

		span {
			background: $color-grey;
		}
	}

	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}

	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}

	.navbar-collapse.collapse {
		display: none!important;
	}

	.navbar-nav {
		float: none!important;
		margin-top: 7.5px;
	}

	.navbar-nav > li {
		float: none;
	}

	.collapse.in {
		display: block !important;
	}
}
@media (max-width: 768px) {
	.collapse.in {
		overflow: visible;
	}
}
// break point nav change--end

.navbar-ventobrasil {
	box-shadow: 0 0 1px rgba(0,0,0,.2), 0 0 15px rgba(0,0,0,.15);
	padding: 0;

	.navbar-header {
		margin: 0 15px;
		text-align: center;
		float: none;
	}
}

.navbar-brand {
	text-align: center;

	img {
		height: 50px;
	}
}

.navbar {
	margin-bottom: 0;
}

.navbar-toggle {
	color: $color-black;
	padding-top: 25px;
	padding-left: 15px;
}

.navbar-nav {
	margin: 0;

	li {
		width: 100%;
		text-align: center;

		&:last-child {
			border-bottom: 1px solid #424242;
		}

		a,
		a:focus,
		a:hover {
			font-size: 12px;
			text-transform: uppercase;
			line-height: 50px;
			text-align: center;
			color: #FFF;
			display: block;
			background: #555;
			border-bottom: 1px solid #424242;
			width: 100%;
			height: 50px;
			padding: 0;
			font-weight: 800;
		}
	}
}
@media (min-width: 992px) {
	.navbar-ventobrasil {
		position: fixed;
		height: 100%;

		.navbar-nav {
			margin-top: 30px;
			margin-right: 0;
			margin-left: 0;

			li {
				width: 100%;
				text-align: center;
				border-top: 1px solid $color-grey-light;

				&:last-child {
					border-bottom: 1px solid $color-grey-light;
				}

				a {
					border-bottom: none;
					background: $color-white;
					height: 40px;
					padding: 0 14px;
					font: 12px/40px $font-open-sans;
					color: #000;
					letter-spacing: 0.8px;
					text-transform: uppercase;
					display: block;
					position: relative;

					&:focus,
					&:hover {
						background-color: transparent;
						-webkit-box-shadow: inset 0 -1px 0 0 rgba(76,76,76,0.21);
						-moz-box-shadow: inset 0 -1px 0 0 rgba(76,76,76,0.21);
						box-shadow: inset 0 -1px 0 0 rgba(76,76,76,0.21);
					}

					&.active {
						box-shadow: inset 0 -2px 0 0 $color-green;
						font-weight: 600;
					}
				}
			}
		}

		.navbar-brand {
			float: none;
			padding: 0 15px;
			text-align: center;

			img {
				height: 100px;
				width: 150px;
				float: none;
				margin-right: auto;
				margin-left: auto;

				&:hover {
					opacity: 0.5;
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	.navbar-ventobrasil {
		.navbar-brand {
			img {
				width: 163px;
			}
		}
	}
}

.language {
	height: 26px;
	border-radius: 4px;
	overflow: hidden;

	&.lvisible {
		height: 80px;
	}

	.lang-select {
		border-radius: 4px;

		&.brbnone {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}
	}

	&:hover {
		height: 80px;
	}
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	z-index: 10;
	text-align: center;

	ul {
		padding-left: 0;
		position: relative;
	}

	li {
		height: 28px;
		border-radius: 4px;
		vertical-align: middle;
		list-style: none;
	}

	li a {
		position: relative;
		text-transform: uppercase;
		width: 22px;
		height: 20px;
		padding: 7px 2px 7px 32px;
		font: bold 10px/10px $font-open-sans;
		color: #000;
		letter-spacing: 1px;
		background: #fff;
		border-top: 1px solid #D5D5D5;
		transition: 0.2s ease;
		vertical-align: middle;

		&:before {
			content: "";
			width: 16px;
			height: 12px;
			position: absolute;
			left: 5px;
			top: 6px;
		}

		&:hover {
			background: #DDD;
		}

		&:active {
			background: #DDD;
		}
	}

	li:first-child a {
		position: relative;
		background: #EEE !important;
		border: none;
		cursor: pointer;
	}
}

.lang-wrapper {
	.lang-select {
		background: #DDD;
	}

	ul {
		text-align: center;
		padding-left: 0;
		position: relative;
	}

	li {
		display: inline-block;
		height: 28px;
		border-radius: 4px;
		vertical-align: middle;
		list-style: none;
	}

	li a {
		position: relative;
		text-transform: uppercase;
		width: 22px;
		height: 20px;
		padding: 6px 6px 6px 32px;
		font: bold 10px/10px $font-open-sans;
		color: #000;
		letter-spacing: 1px;
		border-top: 1px solid #D5D5D5;
		transition: 0.2s ease;
		vertical-align: middle;

		&:before {
			content: "";
			width: 16px;
			height: 12px;
			position: absolute;
			left: 5px;
			top: 6px;
		}

		&:hover {
			background: #DDD;
		}

		&:active {
			background: #DDD;
		}
	}
}

.en a:before {
	background: url('../img/gb.png');
	background-repeat: no-repeat;
	background-position: center;
}

.pl a:before {
	background: url('../img/pl.png');
	background-repeat: no-repeat;
	background-position: center;
}

.br a:before {
	background: url('../img/br.png');
	background-repeat: no-repeat;
	background-position: center;
}

.margin-top {
	margin-top: 30px;
}
@media (min-width:769px) {
	background-position: left;
}
