@import './node_modules/bootstrap-sass/assets/stylesheets/bootstrap'; //Load bootstrap framework
@import './node_modules/toastr/toastr.scss'; //Load bootstrap toastr
@import 'variables';
@import 'footer';
@import 'animate';
@import 'carousel';
@import 'questbook';
@import 'btn';
@import 'nav';
@import 'reservation-form';
@import 'contact-form';
@import 'box';
@import 'room';
@import 'gallery';
@import 'accomodations';
@import 'section';
@import 'fancybox';
@import 'testimonials';
@import 'add-comment';
@import 'loader';
@import 'site-map-list';
@import 'frontend';
@import 'stats';

.img-fit {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

main {
	font: 14px/25px $font-open-sans;
	color: $color-black;
}

.no-padding {
	padding: 0 !important;
}

.bg {
	background-image: url('../img/pattern-concrete-fade.png');
	background-position-x: left;
    background-position-y: top;
    background-size: initial;
	background-repeat: repeat-x;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial

}

.bg-grey {
	background-image: url('../img/fadeback.jpg')!important;
	background-size: 100% 100% !important;
}

body,
html {
	font-size: 16px;
}

.content {
	padding: 30px 15px;
}

.content-contact {
	padding: 10px 15px;
}
@media (min-width: 768px) {
	body,
	html {
		font-size: 20px;
		color: $color-grey;
	}

	.content {
		padding: 50px 50px 20px;
	}

	.content-contact {
		padding: 20px 50px;
	}
}
