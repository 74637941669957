.questbook {
	background-image: url('/../../public/ventobrasil/img/pattern-concrete-fade.png');
	background-repeat-x: repeat;
	background-position-x: left;
	background-position-y: top;
	background-size: initial;
	background-repeat-x: repeat;
	background-repeat-y: no-repeat;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: rgb(245, 245, 245);

	padding: 30px 0px;

	.review {
		color: $color-black;

		&-title {
			margin: -7px 0 20px;
			font: bold 25px/26px $font-open-sans;
			letter-spacing: 0;
		}

		&-content {
			margin-bottom: 27px;
			font: 14px/25px $font-open-sans;
			letter-spacing: 0.4px;
		}

		&-author {
			margin-top: 20px;
			font: 12px/18px $font-open-sans;
			letter-spacing: 0.4px;
			text-transform: uppercase;
			float: right;
		}
	}

	.reviews-list {
		.recent-review {
			min-height: 50px;
			padding: 17px 20px;
			color: #000;
			background: #E5E5E5;
			display: block;
			position: relative;
			z-index: 1;

			&-date {
				width: 45px;
				margin: 4px 20px 0 0;
				background: #FFF;
				box-shadow: 1px 1px 0 rgba(0,0,0,.1);
				border-radius: 5px;
				float: left;
				overflow: hidden;

				&-month {
					height: 18px;
					font: 800 10px/18px $font-open-sans;
					letter-spacing: 1px;
					text-align: center;
					color: #FFF;
					text-transform: uppercase;
					display: block;
					background-color: $color-green;
				}
				&-day {
					height: 27px;
					font: italic 23px/22px 'Poly', georgia;
					text-align: center;
					display: block;
				}
			}
			&-title{
				    font: bold 14px/20px $font-open-sans;
			}
			&-content{
					padding-left: 65px;
				    font: 12px/20px $font-open-sans;
			}
		}
	}
}
.gb-content{
	padding: 50px 15px;
}
@media (min-width: 768px) {
	.gb-content{
		padding: 50px 50px 20px;
	}
}
