footer {
	.wrapper-footer {
		display: block;
		height: 350px;
	}

	.newsletter-wrapper {
		padding: 50px 0;
		height: 200px;
		background-color: $color-grey;
	}

	.newsletter {

		position: relative;
		vertical-align: middle;
		text-align: center;

		&-title {
			margin-bottom: 35px;
			height: 40px;
			vertical-align: middle;

			.name {
				text-align: center;
				font: italic 28px/20px 'Poly', georgia;
				color: #FFF;
				display: inline-block;
				vertical-align: middle;
			}

			img {
				display: inline-block;
			}
		}

		.subscribe {
			margin-bottom: 35px;
			display: inline-block;
			.email-input {
				width: 60%;
				height: 40px;
				font: 11px/10px $font-open-sans;
				color: #000;
				letter-spacing: 0.4px;
				text-indent: 12px;
				border-radius: 5px 0 0 5px;
				border: none;

				float: left;
			}
		}
	}

	.social-media {
		text-align: center;
		display: table-cell;
		margin-bottom: 15px;

		a {
			width: 30px;
			height: 30px;
			margin: 0 7px 5px 0;
			color: $color-grey;
			line-height: 30px;
			text-align: center;
			display: inline-block;
			border-radius: 100px;
			background: $color-white;
		}

		.instagram {
			color: $color-white;
			background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
			background: -moz-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
			background: -ms-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
			background: -o-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
			background: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);

			&:hover {
				color: $color-black;
			}
		}

		.tripadvisor {
			background: #629d2a;
			color: $color-black;

			&:hover {
				color: $color-white;
			}
		}

		.facebook {
			background: #3b5998;
			color: $color-white;

			&:hover {
				color: #dfe3ee;
			}
		}
	}

	.contact {
		background-color: $color-grey;
		padding: 20px 25px;
		font: 14px/25px $font-open-sans;
		color: #FFF;
		letter-spacing: 0.4px;
		float: right;
		box-sizing: border-box;
		position: relative;
		height: 100%;
		text-align: center;
	}
}

.footerlinks {
	width: 100%;
	min-height: 80px;
	font: 10px/70px $font-open-sans;
	color: $color-white;
	letter-spacing: 1.5px;
	text-align: center;
	text-transform: uppercase;
	background: $color-black;
	clear: both;

	ul {
		padding-left: 0;

		li {
			display: inline-block;
			padding: 2px 12px;

			a {
				color: $color-white;

				&:hover {
					text-decoration: none;
					color: lighten($color-grey, 20);
				}
			}
		}
	}
}
@media (min-width:768px) {
	footer {
		.newsletter-wrapper {
			height: 100%;
				padding: 50px 20px;

			.newsletter {
				top: 35%;
				transform: translateY(-50%);

				&-title {
					margin-bottom: 15px;
				}
			}

			.subscribe {
				margin-bottom: 15px;
			}
		}
		.contact{
			padding: 50px 25px;
			background-color: darken($color-grey, 10);
			text-align: left;
		}
	}
}
