.button-vb {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	padding: 25px 80px;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.button-vb:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.button-vb:before {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: antialiased;
}

.btn-vb {
	height: 50px;
	padding: 0;
	font: 800 12px/50px $font-open-sans;
	letter-spacing: 1.2px;
	text-align: center;
	text-transform: uppercase;
	display: inline-block;
	border-radius: 5px;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;

	&-green {
		background-color: $color-green;
		color: $color-white;
		margin: 20px 0;
	}

	&-white {
		margin: 20px 0;
		border: 2px solid $color-green;
		border-radius: 5px;
		background-color: $color-white;
		color: $color-green;
		padding: 0 5px;

		.fa-check-circle {
			font-size: 16px;
			margin-left: 5px;
		}
	}

	&-white-border {
		background-color: $color-green;
		color: white;
		border: 2px solid white;
		border-radius: 4px;
		padding-left: 10px;
		padding-right: 10px;
	}

	&-grey {
		//padding: 0 15px;
		color: #000;
		background: #E5E5E5;
		box-shadow: 1px 1px 0 rgba(0,0,0,.15);
		margin: 15px 0;
	}

	&-submit {
		padding: 0;
		background-color: $color-green;
		color: $color-white;
		width: 40%;
		height: 40px;
		line-height: 40px;
		border-radius: 0 5px 5px 0;
		float: left;
		font-size: 10px;
	}

	&-form {
		color: $color-white;
		width: 15%;
		height: 55px;
		line-height: 55px;
		background: #333;
		border-radius: 0 5px 0 0;
	}
}

.btn-vb-green:active,
.btn-vb-green:hover {
	background: $color-black;
}

.btn-vb-white-border:active,
.btn-vb-white-border:hover {
	background: $color-white;
	color: $color-green;
}

.btn-vb-white:active,
.btn-vb-white:hover {
	background: $color-green;

	.fa-check-circle {
		color: $color-white;
		//margin-right: 10px;
		//
		font-weight: 400;
	}
}

.btn-vb-form:active,
.btn-vb-form:hover,
.btn-vb-grey:active,
.btn-vb-grey:hover {
	background: $color-green;
}

.btn-vb span {
	display: inline-block;
	width: auto;
	height: 100%;
	-webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	transition: all 0.3s;
	backface-visibility: hidden;
}

.btn-vb:before {
	position: absolute;
	height: 100%;
	width: 100%;
	font-weight: 800;
	text-transform: uppercase;
	font: 800 12px/50px $font-open-sans;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.btn-vba:hover span {
	-webkit-transform: translateY(300%);
	-moz-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}

.btn-vba:before {
	left: 0;
	top: -100%;
}

.btn-vba:hover:before {
	top: 0;
}

.about,
.btn-instagram {
	padding: 0 25px;

	&:before {
		color: $color-green;
	}
}

.review-add {
	width: 100%;
}

.testimonial-add {
	&:before {
		color: $color-green;
	}
}

.form-send {
	&:before {
		color: $color-white;
	}
}

.contact-btn {
	width: 100%;

	&:before {
		color: $color-green;
	}
}

.review-write:before {
	color: $color-white;
}

.review-submit:before {
	color: $color-white;
}

.submit:before {
	font-size: 10px;
	background-color: black;
	height: 40px;
	line-height: 40px;
}

.reservation {
	float: none;
	padding: 0;

	&:before {
		color: white;
		left: 0;
	}
}

.btn-bootom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}
@media (min-width: 768px) {
	.reservation {
		float: left;
	//	margin: 20px 15px;

		&:before {
			left: -10px;
		}
	}

	.contact-btn {
		margin-top: 0;
	}
}
@media (min-width: 992px) {
	.reservation {
		width: 90%;
	}
}
