.section {
	&-top-img {
		border: 1px solid $color-grey-light;
		padding: 5px;
		img{
			max-height: 450px;
		}
	}

	&-title,
	&-title h2 {
		margin: 50px 0;
		font: 600 22px/30px $font-open-sans;
		text-transform: uppercase;
		letter-spacing: 1.2px;
	}

	&-description {
		padding-bottom: 60px;
	}
	&-description-contact {
		padding-bottom: 60px;
		background: transparent;
			padding-top: 100px
	}
}
@media (min-width: 768px) {
	.section{
		&-description-contact {
			padding-top: 0px

		}
	}
}
