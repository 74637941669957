.subpage {
	position: relative;
	height: 415px;

	&:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		opacity: 0.9;
		background-color: $color-green;
		z-index: 1;
	}

	.carousel-inner .item img {
		height: 415px;
	}

	.carousel-caption {
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font: italic 40px/45px 'Poly', georgia;
		text-shadow: none;
		z-index: 99999;
		padding-top: 0;
		width: 100%;
		bottom: 0;


		.tagline {
			padding: 10px 50px 0;
			font: 800 12px/18px $font-open-sans;
			letter-spacing: 1.5px;
			text-transform: uppercase;
			border-top: 1px solid rgba(255,255,255,.25);
		}
	}
}

.wrapper-carousel {
	height: 500px;
	position: relative;
}

.mainpage,
.mainpage .carousel-inner img,
.wrapper-carousel {
	height: 65vh;
}

.mainpage {
	display: none;
}

#carousel-ventobrasil {
	.carousel-info {
		position: absolute;
		bottom: 0;
		right: inherit;
		background-color: white;
		color: $color-black;
		padding: 5px 17px 10px;
		font: italic 40px/45px 'Poly', georgia;
		letter-spacing: -.5px;
		background: rgba(255,255,255,.85);
		box-shadow: 1px 1px 0 rgba(0,0,0,.1);
		border-radius: 0;

		p {
			margin: 0;
		}
	}
}
@media (min-width: 768px) {
	#carousel-ventobrasil {
		.carousel-info {
			bottom: 20%;
			right: 5%;
			border-radius: 5px;
		}
	}

	.subpage {
		height: 55vh;
		.carousel-inner .item img {
			height: 55vh;
		}

		.tagline {
			width: 70%;
			float: none;
			margin: auto;
		}
	}
}
