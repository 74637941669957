.form-wrapper-contact {
	position: relative;
	z-index: 9999;
	border-radius: 5px;
	background: #cce0d6;
	top: 20px;
	box-shadow: 0 0 1px rgba(0,0,0,.2), 0 0 10px rgba(0,0,0,.15);
	width: 100%;
	padding: 27px 0;

	.field {
		margin-bottom: 7px;
		background: #FFF;
		border-radius: 3px;
		box-shadow: 1px 1px 0 rgba(0,0,0,.1);

		input {
			width: 100%;
			height: 45px;
			padding: 0 15px;
			font: 14px/20px $font-open-sans;
			letter-spacing: 0.4px;
			border: none;
			box-sizing: border-box;
			background: none;
			z-index: 2;
			position: relative;
		}

		textarea {
			height: 149px;
			padding: 12px 15px;
			display: block;
		}
	}


}
@media (min-width:992px) {
	.form-wrapper-contact {
		left: 50%;
		width: 90%;
		margin-left: -45%;
		padding: 40px 20px;
		top: -73px;

	}
}

.form-group.has-error {
	box-sizing: border-box;
	border: 1px solid red;
	color: red;
	background-color: white;
}

.err-window {
	text-align: center;
	margin: 0 20px;
	padding: 20px;
	height: 45px;
	padding: 0 15px;
	font: 18px/45px $font-open-sans;
	letter-spacing: 0.4px;
	border-radius: 3px;
	box-shadow: 1px 1px 0 rgba(0,0,0,.1);
	overflow: hidden;
	margin-bottom: 20px;
}

#contact .form-group.has-error input {
	height: 43px;
}
.grecaptcha-badge {
	visibility: hidden!important;
}
