.room--box,
.wrapper-box {
	text-align: center;

	.box {
		text-align: center;
		padding-top: 15px;

		&-title {
			margin: 10px 0;
			font-weight: bold;

			h3 {
				margin-bottom: 3px;
				font: 700 16px/22px $font-open-sans;
				letter-spacing: 0.5px;
				text-transform: none;
			}
		}

		&-icon {
			width: 70px;
			height: 70px;
			font-size: 22px;
			line-height: 66px;
			text-align: center;
			border: 2px solid green;
			border-radius: 100px;
			float: none;
			margin-right: auto;
			margin-left: auto;

			.fa {
				color: green;
			}
		}

		&--left {
			padding: 30px 0;
		}
	}
}

.room--box {
	padding-top: 45px;
}
@media (min-width: 758px) {
	.room--box,
	.wrapper-box {
		.box {
			padding-top: 0;
		}
	}
}
