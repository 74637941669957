.stats {
	text-align: center;
	text-transform: uppercase;
	margin: 60px 0 75px;
	padding: 50px;
	color: #FFF;
	background: top left repeat-x;
	border-radius: 5px;
	overflow: hidden;
	background-color: $color-green;

	h3 {
		font-size: 16px;
		margin: 0;
		padding-bottom: 47px;
		font-weight: 800;
		border-bottom: 1px solid rgba(255,255,255,.2);
	}

	.counterwrapper {
		padding-top: 30px;
		float: left;
		position: relative;
		text-align: center;

		&::before {
			content: "";
			width: 10px;
			height: 10px;
			margin-left: -7px;
			border: 2px solid #FFF;
			border-radius: 10px;
			position: absolute;
			top: -7px;
			left: 50%;
		}
	}

	.count,
	.counted {
		text-align: center;
		font: italic 50px/75px 'Poly', georgia;
		letter-spacing: 1px;
		text-shadow: 2px 2px 0 rgba(0,0,0,.1);
	}

	p {
		padding-top: 15px;
		font: 13px/20px $font-open-sans;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}

.instagram-section {
	text-align: center;
	border-radius: 5px;
	overflow: hidden;

	a {
		font-size: 14px;
		margin: 0 8px;
		color: #000;
		font-weight: 800;
		border-bottom: 1px solid #E5E5E5;
		text-transform: uppercase;
	}
}

.featurelist {
	text-align: center;
	background: #EEE url("../img/fadeback.jpg");
	background-size: 100% 100%;
	position: relative;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07), inset 0 1px 0 0 rgba(0, 0, 0, 0.05), inset 0 20px 10px -17px rgba(0, 0, 0, 0.05);
	z-index: 1;

	.centre {
		padding: 50px 50px 20px;
		width: 100%;
		z-index: 1;
	}
	h2 {
		margin: -20px 0 55px;
		font: italic 40px/45px 'Poly', georgia;
		text-align: center;
		letter-spacing: -.3px;
		text-transform: none;
	}

}

.featurelist {
	box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	overflow: hidden;
}

.feature {
	width: 100%;
	margin-bottom: 1px;
	padding: 40px 10px;
	background: rgba(255, 255, 255, 0.5);
	border-top: 1px solid rgba(255, 255, 255, 0.6);
	box-sizing: border-box;

	&:last-child {
		margin: 0;
	}

	.thumb {
		margin-right: auto;
		margin-left: auto;
		float: none;
		background: no-repeat center center;
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		width: 120px;
		height: 120px;
		margin-top: -10px;
		margin-bottom: 20px;
		border-radius: 60px;
		border: 5px solid #FFF;
		box-sizing: border-box;
		box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.12);
		transition: all 0.3s ease-in-out;

		&:hover {
			border-radius: 0;
			cursor: pointer;
		}
	}

	.details {
		text-align: center;
		padding: 0 40px;
		line-height: 20px;
		float: left;
		box-sizing: border-box;

		h3 {
			margin-bottom: 3px;
			font: 800 16px/22px $font-open-sans;
			letter-spacing: 1.5px;
			text-transform: uppercase;
		}

		a {
			padding: 10px 10px 10px 0;
			font: 800 12px/20px $font-open-sans;
			letter-spacing: 1.5px;
			text-transform: uppercase;
			border: none;

			i {
				margin-right: 3px;
				font-size: 14px;
				position: relative;
				bottom: -1px;
			}
		}
	}

	.copy {
		text-align: left;
	}

	&:after {
		content: "";
		display: block;
		clear: both;
	}

	&:hover .thumb {
		border-radius: 0;
		cursor: pointer;
	}

	a:hover {
		cursor: pointer;
	}
}

.padd-bottom {
	padding-bottom: 60px;
}
@media (min-width: 992px) {
	.feature {
		.thumb {
			float: left;
		}
	}

	.stats {
		.count,
		.counted {
			font: italic 60px/75px 'Poly', georgia;
		}
	}
}
@media (min-width: 1200px) {
	.stats {
		.count,
		.counted {
			font: italic 70px/75px 'Poly', georgia;
		}
	}
}
