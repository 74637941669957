.form-wrapper {
	position: absolute;
	z-index: 15;
	padding-left: 0;
	list-style: none;
	text-align: center;
	bottom: 0;
	width: 100%;
	margin-bottom: 0;
	margin-left: 0;
	height: auto;
}
@media (min-width:992px) {
	.form-wrapper {
		left: 50%;
		width: 90%;
		margin-left: -45%;
	}
}

.ui-datepicker {
	background: #EEE;
	width: 800px !important;
	margin-top: -195px !important;
	margin-left: -400px !important;
	font: 14px/20px $font-open-sans;
	float: left;
	display: none;
	z-index: 999999 !important;
	box-shadow: 1px 1px 0 rgba(0,0,0,0.12);
	position: fixed !important;
	top: 50% !important;
	left: 50% !important;
}

.ui-datepicker * {
	position: relative;
	z-index: 2;
}

.ui-datepicker:before {
	content: "";
	width: 100%;
	height: 100%;
	outline: 9999px solid rgba(30,30,30,.6);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.ui-datepicker-group {
	width: 50%;
	float: left;
}

.ui-datepicker-header {
	position: relative;
	background: #333;
	height: 55px;
}

.ui-datepicker-title {
	font: bold 16px/53px 'Open Sans', georgia !important;
	color: #FFF;
	letter-spacing: 0.5px;
}

.ui-datepicker-group-first .ui-datepicker-header {
	border-right: 1px solid #222;
}

.ui-datepicker .ui-datepicker-title {
	font-size: 16px;
	font-weight: bold;
	line-height: 28px;
	text-align: center;
}

.ui-datepicker table {
	width: 100%;
	//margin: 0;
	//padding: 15px 28px 28px;
	padding: 15px 28px 28px;
}

.ui-datepicker-group-first table {
	border-right: 1px solid #DDD;
}

.ui-datepicker th {
	//max-width: 55px;
	//width: 25px;
	padding: 5px 5px 20px;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #666;
	text-align: center;
}

.ui-datepicker td a,
.ui-datepicker td span {
	display: block;
	padding: 13px 5px;
	text-decoration: none;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	font-size: 14px;
	background: #FFF;
	color: #666;
	text-align: center;
	border: 1px solid $color-white;
}

.ui-state-active,
.ui-state-focus,
.ui-state-hover,
.ui-widget-content .ui-state-active,
.ui-widget-content .ui-state-focus,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-active,
.ui-widget-header .ui-state-focus {
	///font-size: 18px;
	// color: #FFF;
	font-weight: bolder;
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.ui-widget-header .ui-state-hover {
	opacity: 0.25;
	cursor: pointer;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
	opacity: 0.25;
	cursor: default;
}

.ui-icon {
	text-indent: -9999px;
	display: block;
}

.ui-datepicker .ui-datepicker-prev .ui-icon:before {
	content: "\f190";
	width: 100%;
	height: 100%;
	font-size: 20px;
	font-weight: normal;
	color: #FFF;
	text-indent: 0;
	font-family: FontAwesome;
	position: absolute;
	top: 0;
	left: 0;
}

.ui-datepicker .ui-datepicker-next .ui-icon:before {
	content: "\f18e";
	width: 100%;
	height: 100%;
	font-size: 20px;
	font-weight: normal;
	color: #FFF;
	text-indent: 0;
	font-family: FontAwesome;
	position: absolute;
	top: 0;
	left: 0;
}

.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
	position: absolute;
	top: 17px !important;
}

.ui-datepicker .ui-datepicker-prev {
	left: 15px;
	z-index: 3;
}

.ui-datepicker .ui-datepicker-next {
	right: 30px;
	z-index: 3;
}

.check {
	width: 100%;
	height: 55px;
	margin-top: -55px;
	position: relative;
	z-index: 10;
}

.check .centre {
	height: 100%;
	padding: 0;
	background: #000;
	border-radius: 5px 8px 0 0;
	opacity: 0.85;
}

.check .field {
	width: 17%;
	height: 55px;
	border-right: 1px solid #333;
	box-sizing: border-box;
	float: left;
	position: relative;
}

.check .field input,
.check .field select {
	width: 100%;
	height: 55px;
	font: 11px/55px $font-open-sans;
	color: #FFF;
	text-indent: 20px;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	background: none;
	cursor: pointer;
	position: relative;
	z-index: 2;
}

.check .field select {
	text-indent: 15px;
}

.check .field select option {
	color: #000;
}

.check::-webkit-input-placeholder {
	color: #FFF;
}

.check .field i {
	font-size: 16px;
	color: #666;
	position: absolute;
	margin-top: 20px;
	right: 20px;
	z-index: 1;
}

.check .field:last-of-type {
	border: none;
}

.check .field select {
	display: none;
}

.check button {
	width: 15%;
	height: 55px;
	line-height: 55px;
	background: #333;
	border-radius: 0 5px 0 0;
}

.check .field:hover i {
	color: #FFF;
}

.hover .check button:hover {
	box-shadow: 1px -1px 0 rgba(0,0,0,.1);
}

#quote-carousel blockquote small,
.block-text a small,
.breadcrumb > .active,
.category_title,
.check li:before,
.latest-tweets a,
.latest-tweets p:before,
.portfolio-filter ul li i,
.rating i,
.readmore,
.shop-desc small,
.slider_layer_01 i,
.social a:hover,
.team-member h4 span,
.topbar.dark .social a:hover,
span.clients-name {
	/*color:#f79800;*/
	color: #01762F;
}

.navbar-default .dropdown-menu li:focus,
.navbar-default .dropdown-menu li:hover a,
.pink {
	background-color: #f79800;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.dropdown-menu > li:hover > a,
.form-header,
.magnifier .st:hover,
.open > .dropdown-toggle.btn-primary,
.pager a,
.pitem .st,
.plan .head,
.services-1 i,
.slider_btn .btn-primary,
.tag-widget a:hover,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	/*border-color: #f79800 !important;
		background-color: #f79800 !important;*/
	//border-color: #d8241b !important;
	background-color: #FFD900 !important;
	color: #01762F !important;
}
/* 2.6 - Select Boxes */
.selectboxit-container {
	width: 100%;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 100%;
	float: left;
	z-index: 2;
}

.selectboxit-container * {
	font: 12px $font-open-sans;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: -moz-none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	outline: none;
	white-space: nowrap;
}

.selectboxit-container span i {
	display: none;
}

.selectboxit-container .selectboxit {
	width: 100% !important;
	height: 100%;
	cursor: pointer;
	display: block;
	overflow: hidden;
	position: relative;
}

.selectboxit-container .selectboxit-options a,
.selectboxit-container span {
	height: 40px;
	line-height: 40px;
	display: block;
}

.selectboxit-container span {
	height: 100%;
	font: 11px/55px $font-open-sans;
	color: #FFF;
	text-transform: uppercase;
	letter-spacing: 1px;
	text-align: left;
}

.selectboxit-option {
	border-bottom: 1px solid #DDD;
}

.selectboxit-option-last {
	border: none;
}

.selectboxit-container .selectboxit:focus {
	outline: 0;
}

.selectboxit-text {
	text-indent: 20px;
}

.selectboxit-container .selectboxit-options {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	min-width: 100% !important;
	width: 100% !important;
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	cursor: pointer;
	display: none;
	z-index: 999999;
	text-align: left;
	border: none;
	border-top: 1px solid #E5E5E5;
	background: #EEE;
	border-radius: 0 0 5px 5px;
	letter-spacing: 0.4px;
	a{
		color: black;
	}
}

.selectboxit-option .selectboxit-option-anchor {
	padding: 0 20px;
}

.selectboxit-option-icon-container {
	float: left;
}

.selectboxit-list {
	box-shadow: 1px 1px 0 rgba(0,0,0,.12);

}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
	background: #DDD;
	color: black;
	text-decoration: none;
}

.form-group.has-error {
    box-sizing: border-box;
    border: 1px solid red;
    color: red;
    background-color: white;
}

.err-window {
    margin: 0 20px;
    padding: 20px;
    height: 45px;
    padding: 0 15px;
    font: 18px/45px 'Open Sans', sans-serif;
    letter-spacing: .4px;
    border-radius: 3px;
    box-shadow: 1px 1px 0 rgba(0,0,0,.1);
    overflow: hidden;
}
