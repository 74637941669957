
.spin:hover {
	-webkit-animation: spin 0.75s ease;
	-webkit-animation: spin 0.75s ease;
	-moz-animation: spin 0.75s ease;
	-ms-animation: spin 0.75s ease;
	-o-animation: spin 0.75s ease;
	animation: spin 0.75s ease;
}
@-webkit-keyframes spin {
	100% {
		transform: rotate(1turn);
	}
}
@keyframes spin {
	100% {
		transform: rotate(1turn);
	}
}
@-moz-keyframes spin {
	100% {
		transform: rotate(1turn);
	}
}
@-o-keyframes spin {
	100% {
		transform: rotate(1turn);
	}
}

.ff-book {
	font-family: "book";
}
