.site-map-list {
	list-style: none;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 1px 1px 0 rgba(0,0,0,.12);
	background: rgba( $color-green, .25 );
	padding-left: 0;
	width: 100%;
	margin: auto;
	li {
		text-align: center;
		width: 100%;
	}

	a {
		color: $color-green;
		text-decoration: none;
		font-size: 20px;
		width: 100%;
		font-weight: 800;
		padding: 15px;
		display: block;

		&:hover {
			color: $color-white;
			background-color: $color-green;
			width: 100%;
		}
	}
}
@media (min-width:768px) {
	.site-map-list {
		width: 80%;
	}
}
